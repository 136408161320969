.nav-item {
  padding-right: 10px;
}

.navpane {
  padding-bottom: 20px;
}

.navpane a{
  text-decoration: none;
}